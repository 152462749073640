<template>
    <ODataGrid :dataObject="treeDataSource"
        ref="treeGridRef"
        :hideMultiselectColumn="props.hideMultiselectColumn"
        hideGridMenu
        hideSystemColumn
        hideActionColumn
        disableNavigation
        :rowclickhandler="()=>{}"
    >
        <!-- Tree controls.  --> 
        <template #cardheader  v-if="showIncludeClosed">
            <div
                v-if="showIncludeClosed"
                class="ms-auto form-check form-switch d-flex gap-1 align-items-center me-3"
            >
                <input
                    id="include-closed-projects"
                    class="form-check-input me-1"
                    type="checkbox"
                    v-model="includeClosed"
                    @change="includeClosedToggle"
                />
                <label
                    for="include-closed-projects"
                    class="form-check-label">
                    {{ $t("Include Closed") }}
                </label>
            </div>
        </template>

        <slot name="treeColumns">
            <template v-if="props.useIcons">
                <ONodeColumn 
                    :field="nodeData.displayField" 
                    :headerName="$t(nodeData.HeaderName)" 
                    boldDisplay 
                    sortable 
                    hideCount
                    :cellTitle="row => row.NodeType"    	
                >
                    <template #default="{row}">
                        <ExpandableCell 
                            :row="row"
                            :class="{ 'crossed' : row.IsClosed }"
                        >
                            <template #value>
                                <span @click="onRowClick(row)" class="cp">
                                    <!-- WP -->
                                    <template v-if="row.NodeType === 'WP'">
                                        <i class="bi bi-box treeIcon"></i> 
                                    </template>
                                    <!-- WBS -->
                                    <template v-else-if="row.NodeType === 'WBS'">
                                        <i class="bi bi-folder treeIcon"></i> 
                                    </template>
                                    <!-- Project -->
                                    <template v-else-if="row.NodeType === 'Project'">
                                        <i class="bi bi-briefcase treeIcon"></i>
                                    </template>

                                    <!-- Contract -->
                                    <template v-else-if="row.NodeType === 'Contract'">
                                        <i class="bi bi-file-earmark-text treeIcon"></i> 
                                    </template>

                                    <!-- Procuement -->
                                    <template v-else-if="row.NodeType === 'Procurement'">
                                        <i class="bi bi-boxes treeIcon"></i> 
                                    </template>
                                    <!-- Bid -->
                                    <template v-else-if="row.NodeType === 'Bid'">
                                        <i class="bi bi-card-list treeIcon"></i> 
                                    </template>
                                    <!-- Bidder -->
                                    <template v-else-if="row.NodeType === 'Bidder'">
                                        <i class="bi bi-person-video treeIcon"></i>
                                    </template>

                                    <!-- OrgUnit -->
                                    <template v-else-if="row.NodeType === 'OrgUnit'">
                                        <i class="bi bi-diagram-3 treeIcon"></i> 
                                        <!-- <i class="bi bi-diagram-3 "></i> --> 
                                    </template>

                                    <span  :class="{
                                                'fw-bold' : row.HasNodes,
                                                'text-decoration-line-through' : row.IsClosed
                                            }"
                                    >
                                        {{ row[nodeData.displayField] }}
                                    
                                    </span>
                                </span>
                            </template>

                            <template #default>
                                <span style="margin-left: 35px" :class="{ 'crossed' : row.IsClosed }" @click="onRowClick(row)">
                                    <!-- WP -->
                                    <template v-if="row.NodeType === 'WP'">
                                        <i class="bi bi-box me-2 treeIcon"></i> 
                                    </template>
                                    <!-- WBS -->
                                    <template v-else-if="row.NodeType === 'WBS'">
                                    <i class="bi bi-folder treeIcon"></i>
                                    </template>
                                    <!-- Project -->
                                    <template v-else-if="row.NodeType === 'Project'">
                                        <i class="bi bi-briefcase treeIcon "></i>
                                    </template>

                                    <!-- Contract -->
                                    <template v-else-if="row.NodeType === 'Contract'">
                                        <i class="bi bi-file-earmark-text treeIcon"></i> 
                                    </template>

                                        <!-- Procuement -->
                                    <template v-else-if="row.NodeType === 'Procurement'">
                                        <i class="bi bi-boxes treeIcon"></i> 
                                    </template>
                                    <!-- Bid -->
                                    <template v-else-if="row.NodeType === 'Bid'">
                                        <i class="bi bi-card-list treeIcon"></i> 
                                    </template>
                                    <!-- Bidder -->
                                    <template v-else-if="row.NodeType === 'Bidder'">
                                        <i class="bi bi-person-video treeIcon"></i>
                                    </template>

                                    <!-- OrgUnit -->
                                    <template v-else-if="row.NodeType === 'OrgUnit'">
                                        <i class="bi bi-diagram-3 treeIcon"></i> 
                                    </template>

                                    <span
                                        class="cp"
                                    >
                                        {{ row[nodeData.displayField] }}
                                    
                                    </span>
                                </span>
                            </template>
                        </ExpandableCell>
                    </template>
                    
                </ONodeColumn>
                <OColumn
                    field="ID"
                    :headerTitle="$t('ID')"
                    :headerName="$t('ID')"
                    hide
                />
                <OColumn
                    field="NodeType"
                    :headerTitle="$t('Type')"
                    :headerName="$t('Type')"
                    hide
                />
                <OColumn
                    field="IsClosed"
                    :headerTitle="$t('Is Closed')"
                    :headerName="$t('Is Closed')"
                    hide
                />

                <OColumn
                    field="OrgUnit_ID"
                    :headerTitle="$t('OrgUnit ID')"
                    :headerName="$t('Is OrgUnit ID')"
                    hide
                />
                <OColumn
                    field="Name"
                    :headerTitle="$t('Name')"
                    :headerName="$t('Name')"
                    hide
                />
                <OColumn
                    field="Title"
                    :headerTitle="$t('Title')"
                    :headerName="$t('Title')"
                    hide
                />
                <OColumn
                    field="NodeLevel"
                    :headerTitle="$t('Node Level')"
                    :headerName="$t('Node Level')"
                    hide
                />
                <OColumn
                    field="HasNodes"
                    :headerTitle="$t('Has Nodes')"
                    :headerName="$t('Has Nodes')"
                    hide
                />
                <OColumn
                    field="NamePath"
                    :headerTitle="$t('Name Path')"
                    :headerName="$t('Name Path')"
                    hide
                />
                <OColumn
                    field="NodeIdPath"
                    :headerTitle="$t('Node ID Path')"
                    :headerName="$t('Node ID Path')"
                    hide
                />

            </template>
            
            <ONodeColumn 
                v-else
                :field="nodeData.displayField" 
                :headerName="$t(nodeData.HeaderName)" 
                boldDisplay 
                sortable 
                hideCount  
            />
        </slot>

    </ODataGrid>
</template>

<script setup lang="ts">
import { ref, watch, defineExpose } from 'vue';
import 'o365.modules.DataObject.extensions.NodeData.ts';
import ONodeColumn from 'o365.vue.components.DataGrid.NodeColumn.vue';
import ExpandableCell from 'o365.vue.components.DataGrid.ExpandableCell.vue';
import { useDataObjectEventListener } from 'o365.vue.composables.EventListener.ts';
import { $t } from 'o365.vue.ts';
import context from 'o365.modules.Context.ts';
import Url from 'o365.modules.url.ts';
import { getQueryParameter, setQueryParameter } from 'o365.modules.utils.url.ts';

const emit = defineEmits(['onIndexChange']);
let urlContext = ref(Url.getParam("Context"));
let prevWhereClause = "";
let hasClosedOrgUnitField = false;

const props = defineProps({
    treeDataSource:{
        type: Object,
        required:  true
    },
    /*
        Declare on what field to display and 
    */
    nodeData: {
        type: Object,
        default: {
            displayField: 'NameAndTitle',
            HeaderName: 'WBS'
        }
    },
    /*
        Configuration on tree filtering parameter;

        config can be idField parentField / idPath if it have.

        idField: 'ID',
        parentField: 'VV_ID',

    */
    configuration: {
        type: Object,
        default: {
            idPathField: 'AccessIdPath',
            type: 'hierarchy'
        }
    },
    /** 
    *   Expands tree to selected level
    */
    expandToLevel: {
        type: Number,
        default:  1
    },
    /*
        If provided hides multiselect column for tree
    */
    hideMultiselectColumn: {
        type: Boolean, 
        default: undefined
    },
    /*
        Provide to  filter tree datasource on current context only. Need to have OrgUnit_ID field!!
    */
    showCurrentContextOnly:{
        type: Boolean,
        default: undefined,
    },
    expandByPrimKey:{
        type: String,
        default: undefined
    }, 
    /*
        Icons enabled only for default wbs tree view.
        If enabled uses icons and enables default columns for tree. ( by default if will not have such columns )
    */
    useIcons:{
        type: Boolean,
        default: undefined
    },
    /*
        Add to show slider for "Include closed" 
    */
    showIncludeClosed: {
        type: Boolean,
        default: undefined
    },
    dontLoadDS: {
        type: Boolean,
        default: undefined
    },
    includeClosed:{
        type: Boolean,
        default: false
    }
});

const includeClosed = ref(props.includeClosed);

function setTreeFilter(){
    prevWhereClause = props.treeDataSource.recordSource.whereClause;

    if (!prevWhereClause.includes("IsClosed = 0") && !includeClosed.value) {
        props.treeDataSource.recordSource.whereClause =  (prevWhereClause ? prevWhereClause + ' AND ' : "") 
                                                            + 'IsClosed = 0'
                                                            + (hasClosedOrgUnitField ? ' AND IsClosedOrgUnit = 0': '');
    }

    if(!props.dontLoadDS){

        if(props.showCurrentContextOnly){

            props.treeDataSource.enableContextFilter(()=>{
                return `[OrgUnit_ID] = ${urlContext.value ?? context.id}`;
            });
        }
        else{
            props.treeDataSource.enableContextFilter();
        }

        props.treeDataSource.load().then((x)=>{
            props.treeDataSource.nodeData.expandToLevel(props.expandToLevel);

            if(props.expandByPrimKey){
                expandByPrimKey(props.expandByPrimKey);
            };
        });
    }
}

context.on("Change", () => {
    urlContext.value = Url.getParam("Context");
    setTreeFilter();
})


useDataObjectEventListener(props.treeDataSource, 'CurrentIndexChanged', ()=>{

    /*
        emiting funtion after index change.
    */
    emit('onIndexChange');

    } 
);


useDataObjectEventListener(props.treeDataSource, 'DataLoaded', async ()=>{

    /*
        emiting funtion after tree is empty.
    */

    if(props.treeDataSource.data.length <1 ){
        emit('onIndexChange');
    }
    
    props.treeDataSource.nodeData.expandToLevel(1);

    } 
);

function enableTree() {
    props.treeDataSource.nodeData.enable();
    props.treeDataSource.nodeData.addConfiguration(props.configuration);

    props.treeDataSource.nodeData.disableDetailsMultiSelect = true;

    hasClosedOrgUnitField = hasField(props.treeDataSource.recordSource.fields, 'IsClosedOrgUnit');
}

function expandByPrimKey(primKey: String){

     props.treeDataSource.nodeData.findNodeByPrimKey(primKey)?.expandTo();

}
enableTree();
setTreeFilter(); 


function includeClosedToggle(){
    if(includeClosed.value){
        props.treeDataSource.recordSource.whereClause = prevWhereClause;
    }
    else{
        props.treeDataSource.recordSource.whereClause = (prevWhereClause ? prevWhereClause + ' AND ' : "") 
                                                        + 'IsClosed  = 0'
                                                        + (hasClosedOrgUnitField ? ' AND IsClosedOrgUnit = 0': '');
    }

    props.treeDataSource.load();
}

function hasField(pFields: any, pField: string) {
    for(let i = 0; i < pFields.length; i++) {
        if(pFields[i].name === pField) return true;
    }
    return false;
}

function onRowClick(item){
    props.treeDataSource.setCurrentIndex(item.index)
}

 async function setTreeCurrentIndex(id){
    let node = props.treeDataSource.nodeData.configurations[0].findNodeById(id)

    if ( node !== null && node.isLoading  ) {
    // if ( node.isLoading &&  node !== null) {
        await node.loadingPromise;
    }
    props.treeDataSource.setCurrentIndex(node.index);
}


defineExpose({
    setTreeCurrentIndex
}); 

</script>

<style scoped>
.treeIcon{
    margin-right: 0.5rem;
    cursor: pointer;
}

.cp{
    cursor: pointer;
}
.crossed *{
    text-decoration: line-through;
}
.crossed::before{
    text-decoration: line-through;
}

</style>
